import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useMediaChange from 'common/useMediaChange';

import { S3_BUCKET_URL, WIN_TO_EARN_SECTION_ID } from 'common/const';
import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';

import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';
import WinToEarnStep from './WinToEarnStep';

import { stepsData } from './data';

import './styles.scss';

const WinToEarnSection:FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();
	const { isDesktop } = useMediaChange();

  return (
		<>
			<video muted autoPlay loop playsInline className='w-full'>
				<source src={S3_BUCKET_URL + 'videos/win-to-earn.mp4'} type="video/mp4" />
			</video>
			<BackgroundImage
				id={WIN_TO_EARN_SECTION_ID}
				Tag='section'
				fluid={getBucketImage(imagesData, 'win-to-earn-bg.png', 'fluidData')}
				className='win-to-earn-bg'
			>
				<div
					className='
						flex
						flex-col
						justify-center
						items-center
						mt-[5%]
						space-y-8
						lg:flex-row
						lg:mt-0
						lg:space-x-8
						safe-space-container
					'
				>
					<div className='flex flex-col justify-center items-center space-y-8 lg:w-2/5 lg:items-start'>
						<StaticImage
							src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/win-to-earn-logo.png'
							alt={t('landing.winToEarn.logoAlt')}
							placeholder='none'
							className='win-to-earn-logo'
						/>
						<h1 className='win-to-earn-title max-w-max'>
							{
								isDesktop
								? t('landing.winToEarn.titleSingleLine')
								: t('landing.winToEarn.titleTwoLines')
							}
						</h1>
						<p className='win-to-earn-description'>
							{t('landing.winToEarn.description')}
						</p>
					</div>
					<div className='flex flex-col justify-center items-center space-y-12 lg:w-3/5'>
						{
							stepsData.map(step => {
								return (
									<WinToEarnStep
										key={step.imgAlt}
										img={getBucketImage(imagesData, step.img, 'imageData')}
										imgAlt={step.imgAlt}
										title={t(step.title)}
										description={t(step.description)}
									/>
								)
							})
						}
					</div>
				</div>
			</BackgroundImage>
		</>
  )
}

export default WinToEarnSection;
