import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { IWinStepProps } from 'common/types';

import { GatsbyImage } from 'gatsby-plugin-image';

import './styles.scss';

const WinToEarnStep:FC<IWinStepProps> = ({
	img,
	imgAlt,
	title,
	description
}) => {
	const { t } = useTranslation();

  return (
    <div
			className='
				flex
				flex-col
				justify-center
				items-center
				lg:flex-row
				lg:space-x-8
			'
		>
			<GatsbyImage
				image={img}
				alt={t(imgAlt)}
				className='wte-step-img'
			/>
			<div className='wte-step-info-container'>
				<h2 className='wte-step-title'>{title}</h2>
				<p className='wte-step-description'>{description}</p>
			</div>
    </div>
  )
}

export default WinToEarnStep;
