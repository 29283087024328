import React, { useEffect, useState } from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { getBucketImage } from 'common/utils';
import { CREATOR_PROGRAM_SECTION_ID, HOME_URL } from 'common/const';

import { StaticImage } from 'gatsby-plugin-image';
import ReelsSlider from './ReelsSlider';
import BackgroundImage from 'gatsby-background-image';
import { PrimeButton } from '@global/Buttons';

import './styles.scss';

const MokensCreatorProgram = ({ imagesData }) => {
	const { t } = useTranslation();
	const { language } = useI18next();

	const [data, setData] = useState([]);

	useEffect(() => {
		setData(imagesData?.allS3Object?.nodes?.filter(node => node?.localFile?.relativePath?.includes('thumbnail')))
	}, []);

  return (
		<BackgroundImage
			Tag='section'
			fluid={getBucketImage(imagesData, 'mkns-creator-program-bg.png', 'fluidData')}
			className='creator-bg'
		>
			<div className='safe-space-container'>
				<div
					className='
						absolute
						w-full
						top-0
						left-1/2
						transform
						-translate-x-1/2
						-translate-y-1/4
						hidden
						space-y-8
						lg:block
					'
				>
					<div className='flex justify-center items-center'>
						<ReelsSlider images={data} />
					</div>
					<div className='flex justify-center items-center'>
						<ReelsSlider images={data} reverseDirection delay={750} />
					</div>
				</div>
				<div
					id={CREATOR_PROGRAM_SECTION_ID}
					className='w-full flex justify-center items-center lg:my-[5%]'
				>
					<div
						className='
							flex
							flex-col
							justify-center
							items-center
							space-y-8
							lg:flex-row
							lg:space-x-8
							xl:space-x-16
						'
					>
						<StaticImage
							src='https://mokens-landing-bucket.s3.amazonaws.com/images/decorations/moken-streamer.png'
							alt={t('landing.creator.imgAlt')}
							placeholder='none'
							className='aspect-[0.76] w-4/5 max-w-[400px] lg:w-1/2 lg:max-w-[800px]'
						/>
						<div className='w-full flex flex-col justify-center items-center space-y-8 lg:w-1/2 lg:items-start'>
							<h1 className='creator-title'>
								{t('landing.creator.title')}
							</h1>
							<p className='creator-description'>
							{t('landing.creator.description')}
							</p>
							<PrimeButton
								link={`${process.env.GATSBY_MONSTER_URL}creator`}
								text={t('landing.creator.cta')}
								external
								className='
									px-10
									py-6
									italic
									lg:max-w-max
								'
							/>
						</div>
					</div>
				</div>
				<div
					className='
						hidden
						w-full
						absolute
						bottom-0
						left-1/2
						transform
						-translate-x-1/2
						translate-y-1/2
						lg:flex
					'
				>
					<ReelsSlider images={data} />
				</div>
			</div>
		</BackgroundImage>
  )
}

export default MokensCreatorProgram;
