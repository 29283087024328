import React, { FC } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { IReelsProps } from 'common/types';

import { GatsbyImage } from 'gatsby-plugin-image';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const ReelsSlider:FC<IReelsProps> = ({
	images,
	delay = 1000,
	speed = 2000,
	reverseDirection = false
}) => {
	const { t } = useTranslation();

  return (
    <Swiper
			modules={[Autoplay]}
			spaceBetween={30}
			slidesPerView={4}
			centeredSlides
			loop
			autoplay={{
				delay: delay,
				reverseDirection: reverseDirection,
			}}
			speed={speed}
			allowTouchMove={false}
		>
			{
				images.map(img => {
					return (
						<SwiperSlide key={img.localFile.relativePath}>
							<GatsbyImage
								image={img.localFile.childImageSharp.gatsbyImageData}
								alt={t('landing.creator.thumbnailAlt')}
							/>
						</SwiperSlide>
					)
				})
			}
		</Swiper>
  )
}

export default ReelsSlider;
