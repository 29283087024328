import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import { useI18next } from 'gatsby-plugin-react-i18next';

import { FooterTheme } from 'common/context';
import { FooterStyle } from 'common/types';
import {
	HERO_EXPLORE_SECTION_ID,
	EXPLORE_SECTION_ID,
	MARKETPLACE_SECTION_ID,
	CREATOR_PROGRAM_SECTION_ID,
	PARTNERS_SECTION_ID,
	WIN_TO_EARN_SECTION_ID,
	MISSION_SECTION_ID
} from 'common/const';

import SEO from '@layout/SEO';
import Header from '@layout/Header';
import Footer from '@layout/Footer';
import HeroAndExploreSection from '@sections/HeroAndExploreSection';
import MokensCreatorProgram from '@sections/MokensCreatorProgram';
import Marketplace from '@sections/Marketplace';
import PartnersSection from '@sections/Partners';
import MissionSection from '@sections/Mission';
import News from '@sections/News';
import FeatureVideo from '@sections/FeatureVideo';

import metaImgPath from 'images/meta-img.png';
import WinToEarnSection from '@sections/WinToEarnSection';
import { init } from 'common/handleLogin';
import { useUserContext } from 'common/UserProvider';
import toast from 'react-hot-toast';

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		allS3Object {
			nodes {
				localFile {
					relativePath
					childImageSharp {
						gatsbyImageData
						fluid(quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
		allFile {
			edges {
				node {
					relativePath
					childImageSharp {
						gatsbyImageData(placeholder: NONE)
						fluid(quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;

const links = [
	{ title: 'layout.hero', url: `/#${HERO_EXPLORE_SECTION_ID}` },
	{ title: 'layout.explore', url: `/#${EXPLORE_SECTION_ID}` },
	{ title: 'layout.winToEarn', url: `/#${WIN_TO_EARN_SECTION_ID}` },
	{ title: 'layout.marketplace', url: `/#${MARKETPLACE_SECTION_ID}` },
	{ title: 'layout.creator', url: `/#${CREATOR_PROGRAM_SECTION_ID}` },
	{ title: 'layout.partners', url: `/#${PARTNERS_SECTION_ID}` },
	{ title: 'layout.mission', url: `/#${MISSION_SECTION_ID}` },
];

const LandingPage = ({ data }) => {
	const { language, languages, t } = useI18next();
	const { setUser } = useUserContext()

	const [error, setError] = useState('');
	const [landingInfo, setLandingInfo] = useState(null);

	useEffect(() => {
		if (error) toast.error(error);
	}, [error]);

	useEffect(() => {
		if (window.location) {
			init(language, languages).then(res => {
				if (res.error) setError(res.error);
				if (res.user) setUser(res.user);
			}).catch(err => { return err })
		}
	}, []);

	useEffect(() => {
		const fetchLandingInfo = async () => {
			const landingRequest = await fetch(`${process.env.GATSBY_LANDING_INFO}`);
			const landingData = await landingRequest.json();
			setLandingInfo(landingData);
		}

		fetchLandingInfo();
	}, [])

	return (
		<div className='bg-black'>
			<SEO
				title={t('page.title')}
				description={t('comingSoon.pageDescription')}
				lang='en'
				imgPath={metaImgPath}
				imgAlt=''
				meta={[]}
			/>
			<main className='overflow-x-hidden'>
				<Header />
				<HeroAndExploreSection imagesData={data} />
				{
					landingInfo &&
					<>
						<News imagesData={data} news={landingInfo.news}/>
						<FeatureVideo videoId={landingInfo.videoId} imagesData={data} />
					</>
				}
				<WinToEarnSection imagesData={data} />
				<Marketplace imagesData={data} isLanding />
				<MokensCreatorProgram imagesData={data} />
				<PartnersSection imagesData={data} />
				<MissionSection />
				<FooterTheme.Provider value={FooterStyle.DARK}>
					<Footer links={links} />
				</FooterTheme.Provider>
			</main>
		</div>
	)
}

export default LandingPage;
