import React, { FC } from 'react';

import useMediaChange from 'common/useMediaChange';
import { INavigationCard } from 'common/types';

import { GatsbyImage } from 'gatsby-plugin-image';
import { PrimeButton } from '@global/Buttons';

const NavigationCard: FC<INavigationCard> = ({
	link,
	external = true,
	img,
	imgAlt,
	buttonText,
	title,
	description,
	icons,
	iconKeys,
	disabled,
	language
}) => {
	const {isDesktop} = useMediaChange();

	const iconSize = isDesktop ? '40px' : '25px';

	return (
		<div className='
				w-[90%]
				max-w-[500px]
				rounded-t-3xl
				mb-8
				mx-auto
				flex
				flex-col
				items-center
				bg-purple-faint
				lg:w-[30%]
				lg:mx-0
			'
		>
			<GatsbyImage
				image={img}
				alt={imgAlt}
			/>
			<div
				className='
					w-[85%]
					flex-grow
					flex
					flex-col
					items-center
					justify-between
					py-4
					space-y-4
				'
			>
				<h1 className='
					font-sofia
					text-center
					font-black
					italic
					uppercase
					text-white
					leading-none
					text-[1.5rem]
				'
				>
					{title}
				</h1>
				<p
					className='
						font-sofia
						text-center
						uppercase
						text-white
					'
				>
					{description}
				</p>
				{icons && <div className='flex space-x-2'>
					{icons.map((icon, index) => {
						return <div key={iconKeys[index]}>{icon({ width: iconSize, height: iconSize })} </div>
					})}
				</div>}
				<PrimeButton
					link={link}
					text={buttonText}
					language={language}
					disabled={disabled}
					external={external}
					className='min-h-[60px]'
				/>
			</div>
		</div>

	)
}

export default NavigationCard;