import React, { FC, useState } from 'react';
import { useI18next } from "gatsby-plugin-react-i18next";
import useMediaChange from 'common/useMediaChange';

import { getBucketImage } from 'common/utils';
import { INews } from 'common/types';

import { GatsbyImage } from 'gatsby-plugin-image';
import LangDepImg from '@global/LangDepImg';
import FadeSlider from '@global/FadeSlider';
import FadeSlide from '@global/FadeSlider/FadeSlide';
import { PrimeButton } from '@global/Buttons';

import './styles.scss';

const News: FC<INews> = ({ imagesData, news }) => {
	const { language } = useI18next();
	const { isDesktop } = useMediaChange();

	const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <section
			className={`
				flex
				justify-center
				items-center
				pt-8
				${news[currentIndex].bg.plain ? 'plain-bg' : 'dynamic-gradient'}
			`}
			style={{
				'--from-color': news[currentIndex].bg.from,
				'--to-color': news[currentIndex].bg.to,
				'--plain': news[currentIndex].bg.plain
				} as React.CSSProperties
			}
		>
			<div className='flex w-[90%] max-w-[640px] lg:max-w-[1300px]'>
				{
					news &&
					<FadeSlider
						pages={news}
						currentIndex={currentIndex}
						setCurrentIndex={setCurrentIndex}
						paginationStyles='my-8'
						paginationSize={`${isDesktop ? 'sm' : 'md'}`}
						paginationDotColor='bg-white'
					>
						{
							news.map((info, index) => (
								<FadeSlide
									key={info[language].title}
									initial={{ opacity: 0 }}
									animate={{
										opacity: index === currentIndex ? 1 : 0,
										display: index === currentIndex ? 'block' : 'none',
									}}
									transition={{ duration: 2 }}
								>
									<div className={`flex flex-col space-y-4 lg:space-x-8 lg:space-y-0 ${info.reverseOrder ? 'lg:flex-row-reverse' : 'lg:flex-row'}`}>
										{
											info.imgUrl
											? <GatsbyImage
												image={getBucketImage(imagesData, info.imgUrl, 'imageData')}
												alt={info[language].imgAlt}
												className='lg:w-[45%]'
											/>
											: <LangDepImg
												images={[getBucketImage(imagesData, info.en.imgUrl, 'imageData'), getBucketImage(imagesData, info.es.imgUrl, 'imageData')]}
												imgAlt={info[language].imgAlt}
												className='lg:w-[45%]'
											/>
										}
										<div className='flex flex-col space-y-4 lg:pt-[3%] lg:w-1/2 xl:pt-[5%]'>
											<h1 className='news-text news-text-title'>{info[language].title}</h1>
											<p className='news-text news-text-description'>{info[language].description}</p>
											{
												!info.noCta &&
												<PrimeButton
													text={info[language].ctaText}
													link={info.link}
													disabled={info.ctaDisabled}
													external={info.externalLink}
													className='h-[60px] lg:max-w-max lg:p-2'
												/>
											}
										</div>
									</div>
								</FadeSlide>
							))
						}
					</FadeSlider>
				}
			</div>
    </section>
  )
}

export default News;
