import React, { FC } from 'react';

import { IFeatureVideo } from 'common/types';

import BackgroundImage from 'gatsby-background-image';

import { getBucketImage } from 'common/utils';

import './styles.scss';

const FeatureVideo: FC<IFeatureVideo> = ({ videoId, imagesData }) => {

  return (
		<BackgroundImage
			Tag='section'
      fluid={getBucketImage(imagesData, 'neon-light-desktop.png', 'fluidData')}
			className='flex justify-center items-center'
		>
			<div className='safe-space-container iframe-container'>
				<div className='youtube-container'>
					<iframe
						src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&loop=1&controls=0&playlist=${videoId}`}>
					</iframe>
				</div>
			</div>
		</BackgroundImage>
  )
}

export default FeatureVideo;
