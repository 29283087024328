import React, { FC, useEffect, useState } from 'react';
import useMediaChange from 'common/useMediaChange';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';
import { PARTNERS_SECTION_ID } from 'common/const';

import { GatsbyImage } from 'gatsby-plugin-image';
import FadeSlider from '@global/FadeSlider';
import FadeSlide from '@global/FadeSlider/FadeSlide';

import './styles.scss';

const PartnersSection: FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();
	const { isMobile, isDesktop } = useMediaChange();

	const [slidePartners, setSlidePartners] = useState([])
	const [currentIndex, setCurrentIndex] = useState<number>(0);

	const partners = [
		{ bucketImg: 'ola-gg-logo.png', alt: t('partners.olaGgLogo') },
		{ bucketImg: 'Kunisports-logo.png', alt: t('partners.kunisportsLogo') },
		{ bucketImg: 'elixir-logo.png', alt: t('partners.elixirLogo') },
		{ bucketImg: 'kru-logo.png', alt: t('partners.kruLogo') },
		{ bucketImg: 'wake-up-logo.png', alt: t('partners.wakeUpLogo') },
		{ bucketImg: 'one-planet-logo.png', alt: t('partners.onePlanetLogo') },
		{ bucketImg: 'fungies-logo.png', alt: t('partners.fungiesLogo') },
		{ bucketImg: 'fitchin-logo.png', alt: t('partners.fitchinLogo') },
		{ bucketImg: 'sura-logo.png', alt: t('partners.suraLogo') },
		{ bucketImg: 'yeeha-logo.png', alt: t('partners.yeehaLogo') },
		{ bucketImg: 'immutable-logo.png', alt: t('partners.immutableLogo') },
		{ bucketImg: 'huru-gaming-logo.png', alt: t('partners.huruGamingLogo') },
		{ bucketImg: 'soul-bound-logo.png', alt: t('partners.soulBoundLogo') },
		{ bucketImg: 'earn-alliance-logo.png', alt: t('partners.earnAllianceLogo') },
		{ bucketImg: 'meta-pro-logo.png', alt: t('partners.metaProLogo') },
		// { bucketImg: 'igg-token-logo.png', alt: t('partners.iggTokenLogo') },
		// { bucketImg: 'leviatan-logo.png', alt: t('partners.leviatanLogo') },
		// { bucketImg: 'blu-mint-logo.png', alt: t('partners.bluMintLogo') },
		// { bucketImg: 'wombo-logo.png', alt: t('partners.womboLogo') },
	];

	const mobileGridSize = 3;

	useEffect(() => {
		const partnersCopy = [...partners];
		const subdividedPartners = [];

		while (partnersCopy.length) {
			const newArray = partnersCopy.splice(0, mobileGridSize);
			subdividedPartners.push(newArray);
		}

		setSlidePartners(subdividedPartners)
	}, []);

	const goForward = () => {
		if (slidePartners.length === currentIndex + 1) {
			setCurrentIndex(0);
		}
		else {
			setCurrentIndex(currentIndex + 1);
		}
	}

	useEffect(() => {
		let interval;

		if (slidePartners.length > 1) {
			interval = setTimeout(goForward, 15000);
		}
		return () => clearInterval(interval);
	}, [currentIndex]);

	return (
		<section id={PARTNERS_SECTION_ID} className='partners-background'>
			<div className='
				h-full
				flex
				flex-col
				items-center
				justify-center
				mx-auto
				safe-space-container
				'
			>
				<h1 className='
					text-center
					text-[2.5rem]
					font-sans-round
					uppercase
					italic
					font-black
					text-white
					leading-none
					pb-[5%]
					lg:text-[3rem]
					'
				>
					{t('partners.title')}
				</h1>
				{!isDesktop ?
					<FadeSlider
						pages={slidePartners}
						currentIndex={currentIndex}
						setCurrentIndex={setCurrentIndex}
						paginationStyles='py-6'
						paginationSize={isMobile ? 'sm' : 'md'}
						className='h-full flex flex-col justify-between '
					>
						{
							slidePartners.map((info, index) => (
								<FadeSlide
									key={`slide-${index}`}
									initial={{ opacity: 0 }}
									animate={{
										opacity: index === currentIndex ? 1 : 0,
										display: index === currentIndex ? 'block' : 'none',
									}}
									transition={{ duration: 2 }}
								>
									<div
										className='
										w-full
										flex
										flex-wrap
										justify-around
										items-center
										h-[500px]
								'>
										{info.map((partner) => (
											<GatsbyImage
												alt={partner.alt}
												key={partner.alt}
												objectFit='contain'
												image={getBucketImage(imagesData, partner.bucketImg, 'imageData')}
												className='max-w-[200px]'
											/>
										))}
									</div>
								</FadeSlide>
							))
						}
					</FadeSlider>
					: <div
						className='
							flex
							flex-wrap
							justify-between
							w-[85%]
							gap-y-8
						'
					>
						{
							partners.map((partner) =>
								<GatsbyImage
									alt={partner.alt}
									key={partner.alt}
									objectFit='contain'
									image={getBucketImage(imagesData, partner.bucketImg, 'imageData')}
									className='max-w-[125px] lg:max-w-[140px] xl:max-w-[195px]'
								/>
							)
						}
					</div>
				}
			</div>
		</section>
	)
}

export default PartnersSection;
