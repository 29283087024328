export const stepsData = [
	{
		img: 'win-to-earn-combo-step-1.png',
		imgAlt: 'landing.winToEarn.steps.firstStep.imgAlt',
		title: 'landing.winToEarn.steps.firstStep.title',
		description: 'landing.winToEarn.steps.firstStep.description'
	},
	{
		img: 'win-to-earn-combo-step-2.png',
		imgAlt: 'landing.winToEarn.steps.secondStep.imgAlt',
		title: 'landing.winToEarn.steps.secondStep.title',
		description: 'landing.winToEarn.steps.secondStep.description'
	},
	{
		img: 'win-to-earn-combo-step-3.png',
		imgAlt: 'landing.winToEarn.steps.thirdStep.imgAlt',
		title: 'landing.winToEarn.steps.thirdStep.title',
		description: "landing.winToEarn.steps.thirdStep.description"
	}
];
