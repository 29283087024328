import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { MISSION_SECTION_ID } from 'common/const';

import { StaticImage } from 'gatsby-plugin-image';

import './styles.scss'

const MissionSection = () => {
	const { t } = useTranslation();

  return (
    <section
			id={MISSION_SECTION_ID}
			className='bg-red-apple flex justify-center'
		>
			<div className='mission-container safe-space-container'>
				<StaticImage
					src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/monster-league-full-logo.png'
					alt={t('landing.mission.logoAlt')}
					className='mission-logo'
				/>
				<p className='mission-description'>
					{t('landing.mission.description')}
				</p>
			</div>
    </section>
  )
}

export default MissionSection;
