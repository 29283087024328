import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useMediaChange from 'common/useMediaChange';

import { THE_GAME_URL, TOURNAMENTS_URL, EXPLORE_SECTION_ID, HERO_EXPLORE_SECTION_ID } from 'common/const';
import { getBucketImage } from 'common/utils';
import { ISectionQueryData } from 'common/types';

import FadeSlider from '@global/FadeSlider';
import FadeSlide from '@global/FadeSlider/FadeSlide';
import NavigationCard from '@global/NavigationCard';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { AndroidIcon, AppleIcon, AirdropIcon, MokenCoinIcon, WindowsIcon } from '@global/iconComponents';

import './styles.scss';

const HeroAndExploreSection:FC<ISectionQueryData> = ({ imagesData }) => {
	const { t } = useTranslation();

	const squareLink = [
		{
			link: THE_GAME_URL,
			img: getBucketImage(imagesData, 'navigation-card-1.png', 'imageData'),
			imgAlt: t('landing.explore.card1.imgAlt'),
			title: t('landing.explore.card1.title'),
			description: t('landing.explore.card1.description'),
			buttonText: t('landing.explore.card1.buttonText'),
			icons: [AndroidIcon, AppleIcon, WindowsIcon],
			iconKeys:['android-icon', 'apple-icon', 'windows-icon'],
			external: false
		},
		{
			link: `${process.env.GATSBY_MONSTER_URL}token`,
			img: getBucketImage(imagesData, 'navigation-card-2.png', 'imageData'),
			imgAlt: t('landing.explore.card2.imgAlt'),
			title: t('landing.explore.card2.title'),
			description: t('landing.explore.card2.description'),
			buttonText: t('landing.explore.card2.buttonText'),
			icons: [MokenCoinIcon],
			iconKeys:['moken-coin-icon']
		},
		{
			link: TOURNAMENTS_URL,
			img: getBucketImage(imagesData, 'navigation-card-3.png', 'imageData'),
			imgAlt: t('landing.explore.card3.imgAlt'),
			title: t('landing.explore.card3.title'),
			description: t('landing.explore.card3.description'),
			buttonText: t('landing.explore.card3.buttonText'),
			icons: [AirdropIcon],
			iconKeys:['airdop-icon'],
			disabled: true,
			external: false
		}
	]

	const { isMobile, isDesktop } = useMediaChange();
	const [currentIndex, setCurrentIndex] = useState<number>(0);

	const goForward = () => {
		if (squareLink.length === currentIndex + 1) {
			setCurrentIndex(0);
		}
		else {
			setCurrentIndex(currentIndex + 1);
		}
	}

	useEffect(() => {
		let interval;

		if (squareLink.length > 1) {
			interval = setTimeout(goForward, 15000);
		}

		return () => clearInterval(interval);
	}, [currentIndex]);

	return (
		<>
			<section id={HERO_EXPLORE_SECTION_ID}>
			<GatsbyImage
				image={getBucketImage(imagesData, 'landing-banner.png', "imageData")}
				alt={t('footer.mokensLogo')}
				className='w-full aspect-[563/791] lg:h-[95vh] lg:aspect-auto'
			/>
			</section>
			<section className='relative gradient-background' id={EXPLORE_SECTION_ID} >
				<StaticImage
					src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/mokens-football-icon.png'
					alt={t('footer.mokensLogo')}
					placeholder='none'
					className='absolute w-1/6 max-w-[200px] left-1/2 -translate-x-1/2 -translate-y-1/2'
					quality={100} />
				<div className='flex flex-col justify-evenly mx-auto safe-space-container'>
					<StaticImage
						src='https://mokens-landing-bucket.s3.amazonaws.com/images/logos/basic-mokens-logo.png'
						alt={t('footer.mokensLogo')}
						placeholder='none'
						className='w-1/2 max-w-[600px] mx-auto mt-[6%]'
						quality={100}
					/>
					<p className='
							font-sofia
							w-4/5
							my-[4%]
							self-center
							text-white
							text-center
							leading-none
							font-sofia-sans
							lg:text-[2rem]
						'
					>
						{t('landing.explore.introText')}
					</p>
					{isDesktop ?
						<div className='flex w-full self-center justify-around'>
							{squareLink.map(info => {
								return (<NavigationCard
									key={info.imgAlt}
									link={info.link}
									external={info.external}
									img={info.img}
									imgAlt={info.imgAlt}
									icons={info.icons}
									iconKeys={info.iconKeys}
									title={info.title}
									description={info.description}
									buttonText={info.buttonText}
									disabled={info.disabled}
								/>)
							})}
						</div>
						: <FadeSlider
							pages={squareLink}
							currentIndex={currentIndex}
							setCurrentIndex={setCurrentIndex}
							paginationStyles='pb-6'
							paginationSize={isMobile ? 'sm' : 'md'}
						>
							{
								squareLink.map((info, index) => (
									<FadeSlide
										key={info.title}
										initial={{ opacity: 0 }}
										animate={{
											opacity: index === currentIndex ? 1 : 0,
											display: index === currentIndex ? 'block' : 'none',
										}}
										transition={{ duration: 2 }}
									>
										<NavigationCard
											link={info.link}
											img={info.img}
											imgAlt={info.imgAlt}
											icons={info.icons}
											iconKeys={info.iconKeys}
											title={info.title}
											description={info.description}
											buttonText={info.buttonText}
											disabled={info.disabled}
										/>
									</FadeSlide>
								))
							}
						</FadeSlider>}
				</div>
			</section>

		</>
	)
}

export default HeroAndExploreSection;